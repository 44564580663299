import React, { useState } from "react";
import axios from "axios";
import {
  List,
  ListItem,
  ListItemText,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  TableContainer,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import PropTypes from "prop-types";
import { useDoin } from "../ContextProviders/MainContextProvider";
import CircularProgress from "@mui/material/CircularProgress";

import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

const BACKEND_DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN;

// import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

const CarInformationTable = React.memo(({ carData, fetchCars, loading }) => (
  <TableContainer component={Paper} style={{ margin: "20px", padding: "20px" }}>
    <Box display="flex" justifyContent="space-between">
      <Typography variant="h6" component="div">
        Car Information
      </Typography>

      <IconButton onClick={fetchCars} disabled={loading}>
        {loading ? <CircularProgress /> : <SwapHorizIcon />}
      </IconButton>
    </Box>
    <Table aria-label="car information table">
      <TableBody>
        <TableRow>
          <TableCell component="th" scope="row">
            Name
          </TableCell>
          <TableCell align="right">{carData.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            Car Type
          </TableCell>
          <TableCell align="right">{carData.car_type}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            License Plate
          </TableCell>
          <TableCell align="right">{carData.license_plate}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
));

CarInformationTable.displayName = "CarInformationTable";

CarInformationTable.propTypes = {
  carData: PropTypes.shape({
    car_type: PropTypes.string.isRequired,
    license_plate: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  fetchCars: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

// Parent Component
const CarChangeComponent = ({ carData }) => {
  const [cars, setCars] = useState([]);
  const [, setSelectedCar] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    user_token,
    selectedProject,
    projects,
    setSelectedProject,
    setProjects,
  } = useDoin();

  const fetchCars = async () => {
    console.log("fetchCars");
    setLoading(true);
    try {
      const response = await axios.post(
        `${BACKEND_DOMAIN}/api/v1/booking/search?token=${user_token}`,
        {
          start_datetime: "2024-03-23 5:00:44 PM",
          end_datetime: "2024-03-25 3:00:44 PM",
          pickup_location: "EVDILOS",
          dropoff_location: "AIRPORT",
          insurance_type: "BASIC",
        }
      );

      setCars(response.data);
      setOpen(true);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching cars:", error);
      setLoading(false);
    }
  };

  const handleChangeCar = async (bookingId, carId) => {
    try {
      const response = await axios.put(
        `${BACKEND_DOMAIN}/api/v1/booking/${selectedProject.id}/changecar/${carId}?token=${user_token}`
      );
      const selected = cars.find((car) => car.car.id === carId);
      setSelectedCar(selected.car);

      const taskIndex = projects.findIndex(
        (task) => task.id === response.data.id
      );

      let updatedTasks;
      if (taskIndex !== -1) {
        // Task exists, so update it
        updatedTasks = projects.map((task, index) =>
          index === taskIndex ? { ...task, ...response.data } : task
        );
      } else {
        // Task doesn't exist, so add it
        updatedTasks = [...projects, response.data];
      }
      setSelectedProject(response.data);
      // Set the new tasks array
      setProjects(updatedTasks);

      setOpen(false);
    } catch (error) {
      console.error("Error changing car:", error);
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <List>
          {cars.map(({ car }) => (
            <ListItem
              button
              key={car.id}
              onClick={() => handleChangeCar(selectedProject.id, car.id)}
            >
              <ListItemText
                primary={`${car.license_plate} - ${car.car_type}`}
              />
            </ListItem>
          ))}
        </List>
      </Dialog>

      <CarInformationTable
        carData={carData}
        fetchCars={fetchCars}
        loading={loading}
      />
    </div>
  );
};

CarChangeComponent.displayName = "CarChangeComponent";

CarChangeComponent.propTypes = {
  carData: PropTypes.shape({
    car_type: PropTypes.string.isRequired,
    license_plate: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default CarChangeComponent;
