import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Container } from "@mui/material";
import ProtectedRoute from "./auth/pages/ProtectedRoute";
import LoginPage from "./auth/pages/LoginPage";
import { useDoin } from "./ContextProviders/MainContextProvider";
import RegisterPage from "./auth/pages/RegisterPage";

import BookingsPage from "./booking/BookingsPage";
import CustomerPage from "./customer/CustomerPage";
import PaymentList from "./payment/PaymentList";
import InsuranceList from "./insurance/InsuranceList";
import LocationList from "./location/LocationList";
import CustomDirectionTimeline from "./plan/PlanPage";
import CarList from "./car/CarList";
// import Mobile from "./mobile/Mobile";
import TimelinePage from "./timeline/TimelinePage";

const ContentContainer = () => {
  const { user_token } = useDoin();
  return (
    <Container
      component="main"
      maxWidth={false}
      disableGutters
      sx={{
        flexGrow: 1,
        height: "100%", // Ensure the container can grow as needed
        overflow: "auto",
      }}
    >
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route
          path="/bookings"
          element={
            <ProtectedRoute>
              <BookingsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/customers"
          element={
            <ProtectedRoute>
              <CustomerPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payments"
          element={
            <ProtectedRoute>
              <PaymentList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/insurance"
          element={
            <ProtectedRoute>
              <InsuranceList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/location"
          element={
            <ProtectedRoute>
              <LocationList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/plan"
          element={
            <ProtectedRoute>
              <CustomDirectionTimeline />
            </ProtectedRoute>
          }
        />
        <Route
          path="/car"
          element={
            <ProtectedRoute>
              <CarList />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/mobile"
          element={
            <ProtectedRoute>
              <Mobile />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/timeline"
          element={
            <ProtectedRoute>
              <TimelinePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <LoginPage />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<Navigate to={user_token ? "/" : "/"} />} />
      </Routes>
    </Container>
  );
};

export default ContentContainer;
