import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import PropTypes from "prop-types";

const ProjectEditModal = ({
  open,
  handleClose,
  handleSubmit,
  handleDelete,
  initialTask = {
    name: "",
    description: "",
    show_in_flow: false,
    show_in_apps: false,
  },
  title,
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  console.log("initialTask", initialTask);
  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    handleDelete();
    setDeleteDialogOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <Formik
        initialValues={{
          name: initialTask.name || "",
          description: initialTask.description || "",
          show_in_flow: initialTask?.show_in_flow || false,
          show_in_apps: initialTask?.show_in_apps || false,
          show_in_payments: initialTask?.show_in_payments || false,
        }}
        onSubmit={handleSubmit}
      >
        {({ submitForm, setFieldValue, dirty }) => (
          <Form>
            <DialogContent>
              <Field
                as={TextField}
                name="name"
                label="Task Name"
                fullWidth
                style={{ marginBottom: "16px" }}
              />
              <Field
                as={TextField}
                name="description"
                label="Task Description"
                fullWidth
                multiline
                rows={4}
                style={{ marginBottom: "16px" }}
              />
              <FormControlLabel
                control={
                  <Field
                    as={Switch}
                    type="checkbox"
                    name="show_in_flow"
                    onChange={(event) =>
                      setFieldValue("show_in_flow", event.target.checked)
                    }
                  />
                }
                label="Show in Flow"
              />
              <FormControlLabel
                control={
                  <Field
                    as={Switch}
                    type="checkbox"
                    name="show_in_apps"
                    onChange={(event) =>
                      setFieldValue("show_in_apps", event.target.checked)
                    }
                  />
                }
                label="Show in Apps"
              />

              <FormControlLabel
                control={
                  <Field
                    as={Switch}
                    type="checkbox"
                    name="show_in_payments"
                    onChange={(event) =>
                      setFieldValue("show_in_payments", event.target.checked)
                    }
                  />
                }
                label="Show in Payments"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button color="primary" onClick={submitForm} disabled={!dirty}>
                Save Task
              </Button>
              <Button
                color="error"
                onClick={handleDeleteClick}
                disabled={false}
              >
                Delete
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this task?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button color="error" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

ProjectEditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  initialTask: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default ProjectEditModal;
