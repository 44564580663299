import React, { useState } from "react";
import Box from "@mui/material/Box";
import "reactflow/dist/style.css";
import { DoinProvider, useDoin } from "./ContextProviders/MainContextProvider";
import { ThemeProvider } from "styled-components";
import { darkTheme } from "./theme";
import { CssBaseline, Snackbar } from "@mui/material";
import { ThemeProvider as MaterialThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";

import "./App.css";
import Header from "./Header";
import Footer from "./Footer";
import ContentContainer from "./ContentContainer";

const theme = createTheme({
  typography: {
    fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#5e92f3", // Example: a shade of blue for primary
      light: "#5e92f3", // Example: a lighter shade of blue
      dark: "#003c8f", // Example: a darker shade of blue
      contrastText: "#fff", // White text for better contrast on blue background
    },
    // Define other colors as needed (secondary, error, etc.)
  },
  header: { backgroundColor: "#454D66", opacity: 1 },
  footer: { backgroundColor: "#454D66", opacity: 1 },

  drawer: { backgroundColor: "FFFFF", opacity: 1 },
  //   Flow TAB
  tasksContainer: { backgroundColor: "white", opacity: 1 },
  taskList: { backgroundColor: "white", opacity: 1 },

  drawerColor: "#efeeb4",
  groupNodeColor: "#F8F7E3",
  headerColor: "#F14202",
  footerColor: "#F14202",
  taskListBackgroundColor: "#F14202",
  // ... other theme options
});

function App() {
  const [triggerRerender, setTriggerRerender] = useState(false);
  const { snackbarOpen, setSnackbarOpen, snackbarMessage } = useDoin();
  //   const [snackbarOpen, setSnackbarOpen] = useState(false);
  //   const [snackbarMessage, setSnackbarMessage] = useState("");

  const triggerUpdate = () => {
    setTriggerRerender((prev) => !prev);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Header />
      <CssBaseline />
      <ContentContainer
        triggerRerender={triggerRerender}
        triggerUpdate={triggerUpdate}
      />
      {/* This box acts as a spacer */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
      <Footer />
    </Box>
  );
}

function AppWithProviders() {
  return (
    <DoinProvider>
      {/* Your custom context provider */}
      <ThemeProvider theme={darkTheme}>
        {/* General theme provider */}
        {/* Custom provider for flow-related state */}
        {/* Provider from the React Flow library */}
        <MaterialThemeProvider theme={theme}>
          {/* Material-UI theme provider */}
          <BrowserRouter>
            <App /> {/* Your main app */}
          </BrowserRouter>
        </MaterialThemeProvider>
      </ThemeProvider>
    </DoinProvider>
  );
}

export default AppWithProviders;
