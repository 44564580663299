import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { AppBar, Box, Toolbar, Button } from "@mui/material";
import { useDoin } from "./ContextProviders/MainContextProvider";
import LogoutIcon from "@mui/icons-material/Logout";
import PropTypes from "prop-types";

const LEFT_BUTTON_GROUP_MARGIN_RIGHT = "20px";
// Inside your component
const HeaderDesktop = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    update_user_token,
    setUserIsAuthenticated,
    user_is_authenticated,
    basicPermissions,
  } = useDoin(); // Use the context

  const handleLogout = () => {
    update_user_token(null); // Update token to null
    setUserIsAuthenticated(false); // Update authentication to false

    navigate("/login"); // Redirect to login
  };

  return (
    <AppBar position="static">
      <Toolbar sx={(theme) => ({ ...theme.header })}>
        {user_is_authenticated && basicPermissions.can_view_bookings && (
          <Button
            color="inherit"
            onClick={() => {
              navigate("/bookings");
            }}
            sx={{
              opacity: location.pathname === "/bookings" ? 1 : 0.5,
              marginRight: LEFT_BUTTON_GROUP_MARGIN_RIGHT,
            }}
          >
            BOOKINGS
          </Button>
        )}
        {user_is_authenticated && basicPermissions.can_view_timeline && (
          <Button
            color="inherit"
            onClick={() => {
              navigate("/timeline");
            }}
            sx={{
              opacity: location.pathname === "/timeline" ? 1 : 0.5,
              marginRight: LEFT_BUTTON_GROUP_MARGIN_RIGHT,
            }}
          >
            TIMELINE
          </Button>
        )}
        {user_is_authenticated && basicPermissions.can_view_customers && (
          <Button
            color="inherit"
            onClick={() => {
              navigate("/customers");
            }}
            sx={{
              opacity: location.pathname === "/customers" ? 1 : 0.5,
              marginRight: LEFT_BUTTON_GROUP_MARGIN_RIGHT,
            }}
          >
            CUSTOMERS
          </Button>
        )}
        {user_is_authenticated && basicPermissions.can_view_payments && (
          <Button
            color="inherit"
            onClick={() => {
              navigate("/payments");
            }}
            sx={{
              opacity: location.pathname === "/payments" ? 1 : 0.5,
              marginRight: LEFT_BUTTON_GROUP_MARGIN_RIGHT,
            }}
          >
            Payments
          </Button>
        )}
        {user_is_authenticated && basicPermissions.can_view_insurances && (
          <Button
            color="inherit"
            onClick={() => {
              navigate("/insurance");
            }}
            sx={{
              opacity: location.pathname === "/insurance" ? 1 : 0.5,
              marginRight: LEFT_BUTTON_GROUP_MARGIN_RIGHT,
            }}
          >
            Insurance
          </Button>
        )}
        {user_is_authenticated && basicPermissions.can_view_assets && (
          <Button
            color="inherit"
            onClick={() => {
              navigate("/car");
            }}
            sx={{
              opacity: location.pathname === "/car" ? 1 : 0.5,
              marginRight: LEFT_BUTTON_GROUP_MARGIN_RIGHT,
            }}
          >
            Car
          </Button>
        )}
        {/* {user_is_authenticated && (
          <Button
            color="inherit"
            onClick={() => {
              navigate("/mobile");
            }}
            sx={{
              opacity: location.pathname === "/mobile" ? 1 : 0.5,
              marginRight: LEFT_BUTTON_GROUP_MARGIN_RIGHT,
            }}
          >
            Mobile
          </Button>
        )} */}
        {user_is_authenticated && basicPermissions.can_view_locations && (
          <Button
            color="inherit"
            onClick={() => {
              navigate("/location");
            }}
            sx={{
              opacity: location.pathname === "/location" ? 1 : 0.5,
              marginRight: LEFT_BUTTON_GROUP_MARGIN_RIGHT,
            }}
          >
            Location
          </Button>
        )}
        {user_is_authenticated && basicPermissions.can_view_plan && (
          <Button
            color="inherit"
            onClick={() => {
              navigate("/plan");
            }}
            sx={{
              opacity: location.pathname === "/plan" ? 1 : 0.5,
              marginRight: LEFT_BUTTON_GROUP_MARGIN_RIGHT,
            }}
          >
            PLAN
          </Button>
        )}

        <Box display="flex" justifyContent="flex-end" width="100%">
          {user_is_authenticated && (
            <Button
              color="inherit"
              onClick={handleLogout}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }} // Adjust the styling here
              endIcon={<LogoutIcon />} // Add the icon to the end of the button
            >
              Logout
            </Button>
          )}
          {location.pathname === "/login" && (
            <Button color="inherit" onClick={() => navigate("/register")}>
              Register
            </Button>
          )}
          {location.pathname === "/register" && (
            <Button color="inherit" onClick={() => navigate("/login")}>
              Login
            </Button>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

HeaderDesktop.propTypes = {
  toggleDrawer: PropTypes.func,
};

export default HeaderDesktop;
