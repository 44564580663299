import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  TableContainer,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import { useDoin } from "../ContextProviders/MainContextProvider";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
const BACKEND_DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN;

const CustomerTable = ({ customer, edit_enabled = false }) => {
  const { user_token } = useDoin();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const customerData = customer;

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ margin: "20px", padding: "20px" }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          Customer Information
        </Typography>
        {edit_enabled && (
          <IconButton onClick={handleOpen}>
            <EditIcon />
          </IconButton>
        )}
        <Table aria-label="customer information table">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Full Name
              </TableCell>
              <TableCell align="right">{customerData.first_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Email
              </TableCell>
              <TableCell align="right">
                <a
                  href={`https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(customerData.email)}&su=${encodeURIComponent("Your Subject")}&body=${encodeURIComponent("Your email body text")}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <IconButton>
                    <EmailIcon />
                  </IconButton>
                </a>
                {customerData.email}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Phone Number
              </TableCell>
              <TableCell align="right">
                {customerData.has_phone_number ? (
                  <a
                    href={`tel:${customerData.phone_number}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <IconButton>
                      <CallIcon />
                    </IconButton>
                  </a>
                ) : (
                  "N/A"
                )}
                {customerData.phone_number}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Customer</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              full_name: customerData.first_name,
              email: customerData.email,
              phone_number: customerData.phone_number,
            }}
            onSubmit={(values, { setSubmitting }) => {
              fetch(
                `${BACKEND_DOMAIN}/api/v1/customer/${customer.id}?token=${user_token}`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    first_name: values.full_name,
                    email: values.email,
                    phone_number: values.phone_number,
                  }),
                }
              )
                .then((response) => response.json())
                //eslint-disable-next-line no-unused-vars
                .then((data) => {
                  // eslint-disable-line no-unused-vars
                  // handle success response
                  setSubmitting(false);
                  handleClose();
                })
                .catch((error) => {
                  // handle error response
                  console.error("Error:", error);
                  setSubmitting(false);
                });
            }}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Field
                  as={TextField}
                  label="Full Name"
                  name="full_name"
                  onChange={handleChange}
                  value={values.full_name}
                  fullWidth
                />
                <Field
                  as={TextField}
                  label="Email"
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                  fullWidth
                />
                <Field
                  as={TextField}
                  label="Phone Number"
                  name="phone_number"
                  onChange={handleChange}
                  value={values.phone_number}
                  fullWidth
                />
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  sx={{ mt: 2 }}
                >
                  Save Changes
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

CustomerTable.propTypes = {
  customer: PropTypes.object.isRequired,
  edit_enabled: PropTypes.bool,
};

export default CustomerTable;
