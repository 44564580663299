import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import { green, red } from "@mui/material/colors";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
// import { IconButton, Typography } from "@mui/material";
// import EditIcon from "@mui/icons-material/Edit";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

1;
const StyledListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  padding: theme.spacing(2),
  margin: theme.spacing(1, 0),
  border: "1px solid",
  borderColor: theme.palette.divider,
}));

const StyledAvatar = styled(Avatar)({
  backgroundColor: green[500],
});

const StyledListItemText = styled(ListItemText)({
  margin: 0,
});

const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
});

const StyledPhoneInTalkIcon = styled(PhoneInTalkIcon)({
  color: "#F2C94C",
});

const formatDateTime = (dateTimeString) => {
  /**
   * Formats a datetime string from 'YYYY-MM-DDTHH:MM' to 'DD-MM-YYYY @HH:MM'.
   *
   * @param {string} dateTimeString - The datetime string to be formatted.
   * @returns {string} - The formatted datetime string.
   *
   * @example
   * formatDateTime('2023-09-12T15:00') // Returns '12-09-2023 @15:00'
   * @example
   * formatDateTime('2022-12-31T23:59') // Returns '31-12-2022 @23:59'
   * @example
   * formatDateTime('2021-01-01T00:00') // Returns '01-01-2021 @00:00'
   */
  // Split the date and time
  const [date, time] = dateTimeString.split("T"); // ['2023-09-12', '15:00']

  // Reformat the date
  const dateArray = date.split("-"); // ['2023', '09', '12']
  const reversedDateArray = dateArray.reverse(); // ['12', '09', '2023']
  const formattedDate = reversedDateArray.join("-"); // '12-09-2023'

  // Remove the last 3 characters of time ('.00' if exists)
  const formattedTime = time.slice(0, -3); // '15:00'

  // Combine them
  const formattedDateTime = `${formattedDate} @${formattedTime}`; // '12-09-2023 @15:00'

  return formattedDateTime;
};

export default function CustomListItem({
  booking,
  //   handleProjectEditClick,
  handleBookingClick,
}) {
  return (
    <StyledListItem onClick={() => handleBookingClick(booking)}>
      <StyledListItemText
        primary={booking.customers.first_name}
        secondary={booking.car.license_plate}
        primaryTypographyProps={{ fontWeight: "fontWeightBold" }}
        secondaryTypographyProps={{ color: "textSecondary" }}
      />
      <StyledBox>
        <Box>
          <ListItemText
            primary={
              <div>
                <div>{formatDateTime(booking.start_date)}</div>
                <Typography
                  component="div"
                  variant="body2"
                  color="textPrimary"
                  align="center"
                >
                  {booking.pickup_location_name}
                </Typography>
                <div style={{ textAlign: "center" }}>→</div>
                <div>{formatDateTime(booking.end_date)}</div>
                <Typography
                  component="div"
                  variant="body2"
                  color="textPrimary"
                  align="center"
                >
                  {booking.dropoff_location_name}
                </Typography>
              </div>
            }
            primaryTypographyProps={{ color: "textPrimary", fontSize: 14 }}
            secondaryTypographyProps={{ color: "textSecondary" }}
          />
        </Box>
        <ListItemAvatar>
          <StyledAvatar>
            <StyledPhoneInTalkIcon />
          </StyledAvatar>
        </ListItemAvatar>
        <ListItemText
          primary={`Owed €${booking.computed_price.owed}`}
          primaryTypographyProps={{
            color: booking.computed_price.owed > 0 ? red[500] : green[500],
            fontWeight: "fontWeightBold",
          }}
        />
      </StyledBox>
      {/* <IconButton onClick={() => handleProjectEditClick(booking)}>
        <EditIcon />
      </IconButton> */}
    </StyledListItem>
  );
}

CustomListItem.propTypes = {
  booking: PropTypes.object.isRequired,
  handleProjectEditClick: PropTypes.func.isRequired,
  handleBookingClick: PropTypes.func.isRequired,
};
