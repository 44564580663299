import React, { useEffect, useState } from "react";
import axios from "axios";
// import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Box,
  Container,
} from "@mui/material";
// import PropTypes from "prop-types";
import { useDoin } from "../ContextProviders/MainContextProvider";
import { Formik, Form, Field } from "formik";

const BACKEND_DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN;

const CarList = () => {
  const { user_token, user_id, setSnackbarOpen, setSnackbarMessage } = useDoin();
  const [cars, setCars] = useState([]);
  const [selectedCar, setSelectedCar] = useState({
    id: null,
    name: "",
    license_plate: "",
    color: "",
    max_passengers: 0.0,
    fuel_type: "", 
    transmission: "", 
    web_title: "", 
    features: "", 
  });
  const [open, setOpen] = useState(false);
  const [openAddForm, setOpenAddForm] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  const handleRowClick = (car) => {
    setSelectedCar(car);
    setOpen(true);
  };

  // const handleAddClick = () => {
  //   setSelectedCar({ id: null, name: "", license_plate: "" });
  //   setOpenAddForm(true);
  // };

  const handleClose = () => {
    setOpen(false);
    setOpenAddForm(false);
    setOpenDeleteConfirm(false);
  };

  const fetchCars = async () => {
    try {
      const response = await axios.get(
        `${BACKEND_DOMAIN}/api/v1/car?token=${user_token}`
      );
      setCars(response.data.data);
    } catch (error) {
      console.error("Error fetching cars:", error);
    }
  };

  useEffect(() => {
    fetchCars();

  }, []);

  const updateItems = (data, cars, setCars) => {
    const carIndex = cars.findIndex((item) => item.id === data.id);
    let updatedCars;
    if (carIndex !== -1) {
      updatedCars = cars.map((item, index) =>
        index === carIndex ? { ...item, ...data } : item
      );
    } else {
      updatedCars = [...cars, data];
    }
    setCars(updatedCars);
  };

  const handleFormSubmit = (values, { setSubmitting }) => {
    const request = selectedCar.id
      ? axios.put(
          `${BACKEND_DOMAIN}/api/v1/car/${selectedCar.id}?token=${user_token}`,
          {...values, user_id}
        )
      : axios.post(
          `${BACKEND_DOMAIN}/api/v1/car?token=${user_token}`,
          values
        );

    request
      .then((response) => {
        setSubmitting(false);
        updateItems(response.data, cars, setCars);

        setSnackbarOpen(true);
        setSnackbarMessage(
          selectedCar.id ? "Car updated" : "Car added"
        );

        handleClose();
      })
      .catch((error) => {
        console.error("Error:", error);
        setSnackbarOpen(true);
        setSnackbarMessage("Error updating car");
        setSubmitting(false);
        handleClose();
      });
  };

  const handleDelete = async () => {
    try {
      //eslint-disable-next-line
      const response = await axios.delete(
        `${BACKEND_DOMAIN}/api/v1/car/${selectedCar.id}?token=${user_token}`
      );

      setCars(
        cars.filter((car) => car.id !== selectedCar.id)
      );
      setSnackbarOpen(true);
      setSnackbarMessage("Car deleted");
      handleClose();
    } catch (error) {
      console.error("Error deleting car:", error);

      setSnackbarOpen(true);
      setSnackbarMessage(
        error.response.data.ui_message || "Error deleting car"
      );
      handleClose();
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="flex-start"
      maxHeight="xl"
      mt={10}
    >
      <Container
        maxWidth="sm"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          style={{
            paddingBottom: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          Cars
          {/* <IconButton onClick={handleAddClick}>
            <AddIcon />
          </IconButton> */}
        </Typography>

        <Divider style={{ marginBottom: "20px" }} />

        <TableContainer
          style={{
            maxHeight: "300px",
            width: "1000px",
          }}
        >
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Color</TableCell>
                <TableCell>Max Passengers</TableCell>
                <TableCell>Fuel Type</TableCell>
                <TableCell>Transmission Type</TableCell>
                <TableCell>Web Title</TableCell>
                <TableCell>Features</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cars.map((car) => (
                <TableRow
                  key={car.id}
                  hover
                  onClick={() => handleRowClick(car)}
                >
                  <TableCell>{car.name}</TableCell>
                  <TableCell>{car.license_plate}</TableCell>
                  <TableCell>{car.color}</TableCell>
                  <TableCell>{car.max_passengers}</TableCell>
                  <TableCell>{car.fuel_type}</TableCell>
                  <TableCell>{car.transmission}</TableCell>
                  <TableCell>{car.web_title}</TableCell>
                  <TableCell>{car.features}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog open={open || openAddForm} onClose={handleClose}>
          <DialogTitle>
            {selectedCar && selectedCar.id
              ? "Edit Car"
              : "Add Car"}
          </DialogTitle>
          <DialogContent>
            <Formik
              initialValues={{
                name: selectedCar.name,
                license_plate: selectedCar.license_plate,
                color: selectedCar.color,
                max_passengers: selectedCar.max_passengers,
                fuel_type: selectedCar.fuel_type,
                transmission: selectedCar.transmission,
                web_title: selectedCar.web_title,
                features: selectedCar.features,
              }}
              onSubmit={handleFormSubmit}
            >
              {({ values, handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Field
                    as={TextField}
                    label="Name"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <Field
                    as={TextField}
                    label="License plate"
                    name="License plate"
                    onChange={handleChange}
                    value={values.license_plate}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <Field
                    as={TextField}
                    label="Color"
                    name="color"
                    onChange={handleChange}
                    value={values.color}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <Field
                    as={TextField}
                    label="Max Passengers"
                    name="max_passengers"
                    type="number"
                    onChange={handleChange}
                    value={values.max_passengers}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <Field
                    as={TextField}
                    label="Fuel Type"
                    name="fuel_type"
                    onChange={handleChange}
                    value={values.fuel_type}
                    fullWidth
                    sx={{ mt: 2 }}
                  />                  <Field
                    as={TextField}
                    label="Transmission Type"
                    name="transmission"
                    onChange={handleChange}
                    value={values.transmission}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <Field
                    as={TextField}
                    label="Web Title"
                    name="web_title"
                    onChange={handleChange}
                    value={values.web_title}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <Field
                    as={TextField}
                    label="Features"
                    name="features"
                    onChange={handleChange}
                    value={values.features}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    sx={{ mt: 2 }}
                  >
                    {selectedCar && selectedCar.id
                      ? "Save Changes"
                      : "Add"}
                  </Button>
                  {selectedCar && selectedCar.id && (
                    <IconButton
                      color="error"
                      sx={{ mt: 2, ml: 2 }}
                      onClick={() => setOpenDeleteConfirm(true)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openDeleteConfirm}
          onClose={() => setOpenDeleteConfirm(false)}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this car?
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteConfirm(false)}>Cancel</Button>
            <Button onClick={handleDelete} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

CarList.propTypes = {};

export default CarList;
