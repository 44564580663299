import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  //   Paper,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  IconButton,
  TableContainer,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import PropTypes from "prop-types";
import { useDoin } from "../ContextProviders/MainContextProvider";
import { Formik, Form, Field } from "formik";

const BACKEND_DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN;

const CustomerList = ({ onClickProjectItem = () => {} }) => {
  const { user_token } = useDoin();
  const [customers, setCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [open, setOpen] = useState(false);

  const handleRowClick = (customer) => {
    setSelectedCustomer(customer);
    setOpen(true);
    onClickProjectItem();
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCustomer(null);
  };
  const fetchCustomers = async () => {
    try {
      const response = await axios.get(
        `${BACKEND_DOMAIN}/api/v1/customer?token=${user_token}`
      );
      setCustomers(response.data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCustomers = customers.filter(
    (customer) =>
      customer.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      customer.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      customer.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  function updateItems(data, customers, setSelectedCustomer, setCustomers) {
    const taskIndex = customers.findIndex((task) => task.id === data.id);

    let updatedTasks;
    if (taskIndex !== -1) {
      // Task exists, so update it
      updatedTasks = customers.map((task, index) =>
        index === taskIndex ? { ...task, ...data } : task
      );
    } else {
      // Task doesn't exist, so add it
      updatedTasks = [...customers, data];
    }
    setSelectedCustomer(data);
    // Set the new tasks array
    setCustomers(updatedTasks);
  }

  return (
    <>
      <Typography variant="h5" style={{ paddingBottom: "20px" }}>
        Customers
      </Typography>

      <Divider style={{ marginBottom: "20px" }} />

      <TextField
        variant="outlined"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: searchTerm && (
            <InputAdornment position="end">
              <IconButton onClick={handleClearSearch} edge="end">
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        style={{ marginBottom: "20px" }}
      />

      <TableContainer style={{ maxHeight: "300px", width: "700px" }}>
        {" "}
        {/* Adjust maxHeight as needed */}
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>Full Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCustomers.map((customer) => (
              <TableRow
                key={customer.id}
                hover
                onClick={() => handleRowClick(customer)}
              >
                <TableCell>{customer.first_name}</TableCell>
                <TableCell>{customer.email}</TableCell>
                <TableCell>{customer.phone_number}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Customer</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              full_name: selectedCustomer?.first_name ?? "",
              email: selectedCustomer?.email ?? "",
              phone_number: selectedCustomer?.phone_number ?? "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              axios
                .put(
                  `${BACKEND_DOMAIN}/api/v1/customer/${selectedCustomer.id}?token=${user_token}`,
                  {
                    first_name: values.full_name,
                    email: values.email,
                    phone_number: values.phone_number,
                  }
                )
                // eslint-disable-next-line no-unused-vars
                .then((response) => {
                  setSubmitting(false);

                  updateItems(
                    response.data,
                    customers,
                    setSelectedCustomer,
                    setCustomers
                  );

                  handleClose();
                })
                .catch((error) => {
                  console.error("Error:", error);
                  setSubmitting(false);
                });
            }}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Field
                  as={TextField}
                  label="Full Name"
                  name="full_name"
                  onChange={handleChange}
                  value={values.full_name}
                  fullWidth
                  sx={{ mt: 2 }}
                />
                <Field
                  as={TextField}
                  label="Email"
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                  fullWidth
                  sx={{ mt: 2 }}
                />
                <Field
                  as={TextField}
                  label="Phone Number"
                  name="phone_number"
                  onChange={handleChange}
                  value={values.phone_number}
                  fullWidth
                  sx={{ mt: 2 }}
                />
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  sx={{ mt: 2 }}
                >
                  Save Changes
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

CustomerList.propTypes = {
  onClickProjectItem: PropTypes.func,
};

export default CustomerList;
