/* eslint-disable */
import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
// import LaptopMacIcon from "@mui/icons-material/LaptopMac";
// import CarRentalIcon from '@mui/icons-material/CarRental';
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PropTypes from "prop-types";
import { useDoin } from "../ContextProviders/MainContextProvider";
import axios from "axios";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  Tab,
  Tabs,
  TextField,
  IconButton,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { formatDateTime } from "../common/utils";
import { red } from "@mui/material/colors";
import { Form, Formik, Field } from "formik";
import PaymentsList from "./PaymentList";

// import IconButton from '@material-ui/core/IconButton';
// import PhoneIcon from '@material-ui/icons/Phone';
// import EmailIcon from '@material-ui/icons/Email';
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

// ...
const BACKEND_DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN;

function RentCarIcon() {
  return (
    <Badge badgeContent="Rent" color="primary">
      <DirectionsCarIcon />
    </Badge>
  );
}

function CarIconWithParkBadge() {
  return (
    <Badge badgeContent="Park" color="primary">
      <DirectionsCarIcon />
    </Badge>
  );
}

function CarIconWithParkBadgeAndRentBadge({ position }) {
  if (position === "left") {
    return (
      <Badge badgeContent="Park" color="primary">
        <RentCarIcon />
      </Badge>
    );
  }
  if (position === "right") {
    return (
      <Badge badgeContent="Rent" color="primary">
        <CarIconWithParkBadge />
      </Badge>
    );
  }
}

CarIconWithParkBadgeAndRentBadge.propTypes = {
  position: PropTypes.string.isRequired,
};

function TabPanel(props) {
  console.log("CustomizedTimeline: TabPanel: ");
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// eslint-disable-next-line no-unused-vars

function BookingTabPanel(tabValue, task, setOpen) {
  return (
    <TabPanel value={tabValue} index={0}>
      <Box display="flex" flexDirection="column" alignItems="start">
        <Typography variant="h6" component="span">
          CUSTOMER
        </Typography>
        <Typography variant="p" component="span">
          {task.customers.first_name}
        </Typography>

        <Typography variant="p" component="span">
          phone: {task.customers.phone_number}
          <IconButton href={`tel:${task.customers.phone_number}`}>
            <PhoneIcon />
          </IconButton>
        </Typography>
        <Typography variant="p" component="span">
          email: {task.customers.email}
          <IconButton href={`mailto:${task.customers.email}`}>
            <EmailIcon />
          </IconButton>
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="start">
        <Typography variant="h6" component="span">
          CAR
        </Typography>
        <Typography variant="p" component="span">
          {task.car.license_plate}
        </Typography>
      </Box>

      <Divider />

      <Box display="flex" flexDirection="column" alignItems="start">
        <Typography variant="h6" component="span">
          LOCATION
        </Typography>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="p" component="span">
            {task.pickup_location_name}
          </Typography>
          →
          <Typography variant="p" component="span">
            {task.dropoff_location_name}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box display="flex" flexDirection="column" alignItems="start">
        <Typography variant="h6" component="span">
          TIME
        </Typography>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="p" component="span">
            {formatDateTime(task.start_date)}
          </Typography>
          →
          <Typography variant="p" component="span">
            {formatDateTime(task.end_date)}
          </Typography>
        </Box>
      </Box>

      <Divider />
      <Box display="flex" flexDirection="column" alignItems="start">
        <Typography variant="h6" component="span">
          FINANCIALS
        </Typography>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="p" component="span">
            INSURANCE/ΑΣΦΑΛΕΙΑ:
          </Typography>
          <Typography variant="p" component="span">
            {task.insurance_name}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="p" component="span">
            OWED/ΧΡΩΣΤΑΕΙ:
          </Typography>
          <Typography variant="p" component="span" style={{ color: red[500] }}>
            {task.computed_price.owed}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="p" component="span">
            TOTAL PAYMENTS:
          </Typography>
          <Typography variant="p" component="span">
            {task.money_from_payments}
          </Typography>
        </Box>
        {/* <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="p" component="span">
            ADJUSTMENTS:
          </Typography>
          <Typography variant="p" component="span">
            {task.computed_price.discount}
          </Typography>
        </Box> */}
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="p" component="span">
            TOTAL:
          </Typography>
          <Typography variant="p" component="span">
            {task.computed_price.total}
          </Typography>
        </Box>
      </Box>

      {EditBookingNote(task, setOpen)}
    </TabPanel>
  );
}

function EditBookingNote(task, setOpen) {
  return (
    <Formik
      initialValues={{ note: task.note || "" }}
      enableReinitialize={true}
      onSubmit={(values) => {
        const url = `${BACKEND_DOMAIN}/api/v1/booking/info/${selectedTask.booking.id}?token=${user_token}`;
        axios
          .put(url, { note: values.note })
          .then((response) => {
            console.log("response", response);
            setSelectedTask({ ...selectedTask, note: values.note });
            //   setOpen(false);
          })
          .catch((error) => {
            console.log("error", error);
          });
      }}
    >
      {({ values, handleChange, dirty }) => (
        <Form>
          <Field
            as={TextField}
            label="Note"
            name="note"
            onChange={handleChange}
            value={values.note}
            fullWidth
            multiline
            sx={{ mt: 2 }}
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            sx={{ mt: 2 }}
            disabled={!dirty}
          >
            UPDATE NOTE
          </Button>
        </Form>
      )}
    </Formik>
  );
}

function MyDialog({ open, setOpen, booking_id, tabValue, setTabValue }) {
  console.log("CustomizedTimeline: MyDialog: ");
  const [task, setTask] = useState(null); // eslint-disable-line no-unused-vars
  const { user_token } = useDoin();
  const handleTabChange = React.useCallback(
    (event, newValue) => {
      setTabValue(newValue);
    },
    [setTabValue]
  );

  const fetch_booking = async (booking_id) => {
    const url = `${BACKEND_DOMAIN}/api/v1/booking/${booking_id}?token=${user_token}`;
    try {
      const response = await axios.get(url);
      console.log("response", response);
      setTask(response.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  React.useEffect(() => {
    fetch_booking(booking_id);
  }, [booking_id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (task === null) {
    return (
      <Dialog open={open} onClose={() => setOpen(false)}>
        <div>Loading...</div>
      </Dialog>
    );
  }
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>INFO</DialogTitle>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="task tabs">
        <Tab label="Info" {...a11yProps(0)} />
        <Tab label="Payments" {...a11yProps(1)} />
      </Tabs>
      {BookingTabPanel(tabValue, task, setOpen)}

      <TabPanel value={tabValue} index={1}>
        <PaymentsList
          booking_id={task.id}
          customer_id={task.customers.id}
          onPaymentCrudSuccess={() => fetch_booking(booking_id)}
          tabValue={tabValue}
        />
      </TabPanel>
    </Dialog>
  );
}

MyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,

  booking_id: PropTypes.number.isRequired,
  tabValue: PropTypes.number.isRequired,
  setTabValue: PropTypes.func.isRequired,
};

function CustomizedTimeline() {
  console.log("CustomizedTimeline: ");
  let start_date = moment();
  const { user_token } = useDoin();
  const [tasks, setTasks] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(start_date);
  const [FormatedDate, setFormatedDate] = useState(
    start_date.format("YYYY-MM-DD")
  );
  const [open, setOpen] = React.useState(false);
  const [selectedTask, setSelectedTask] = React.useState({});
  const [tabValue, setTabValue] = React.useState(0);

  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = date.format("YYYY-MM-DD");
      console.log("formattedDate", formattedDate);
      setSelectedDate(date);
      setFormatedDate(formattedDate);
      fetchTasks(formattedDate); // Pass formattedDate directly
    }
  };
  async function fetchTasks(date) {
    console.log("CustomizedTimeline: fetchTasks: ", date);
    const url = `${BACKEND_DOMAIN}/api/v1/tas/${date}?token=${user_token}`;
    setTasks([]);

    try {
      const response = await axios.get(url);
      console.log("response", date, response);
      setTasks(response.data.data);
    } catch (error) {
      console.info("Error fetching tasks:", error);
      setTasks([]);
    }
  }

  React.useEffect(() => {
    console.log("CustomizedTimeline: useEffect: ", FormatedDate);
    fetchTasks(FormatedDate);
  }, [FormatedDate]);

  const disabledDates = [
    // moment("2024-08-20"),
    // moment("2024-08-21"),
    // moment("2024-08-22"),
    // ... more dates
  ];

  // Function to disable dates from the list
  const disableDates = (date) => {
    return disabledDates.some((disabledDate) =>
      date.isSame(disabledDate, "day")
    );
  };

  const handleItemClick = (task) => {
    console.log("clicked on", task);
    // RESET TABS
    setTabValue(0);
    setSelectedTask(task);
    setOpen(true);
  };

  // eslint-disable-next-line no-unused-vars
  if (tasks.length === 0) {
    return (
      <>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginBottom={4}
          marginTop={4}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Select Date"
              value={selectedDate}
              onChange={handleDateChange}
              shouldDisableDate={disableDates}
              renderInput={(params) => <TextField {...params} />}
              inputFormat="YYYY-MM-DD"
            />
          </LocalizationProvider>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          //   style={{ height: "100vh" }}
        >
          <Typography variant="h6" component="span">
            No tasks for this date
          </Typography>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom={4}
        marginTop={4}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Select Date"
            value={selectedDate}
            onChange={handleDateChange}
            shouldDisableDate={disableDates}
            renderInput={(params) => <TextField {...params} />}
            inputFormat="YYYY-MM-DD"
          />
        </LocalizationProvider>
      </Box>
      {tasks.map((task) => (
        <Timeline position={task.position} key={task.id}>
          <TimelineItem onClick={() => handleItemClick(task)}>
            <TimelineOppositeContent
              sx={{ m: "auto 0" }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              {task.time}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot
                sx={{
                  backgroundColor: task.eventContainerStyle.backgroundColor,
                }}
              >
                <CarIconWithParkBadgeAndRentBadge
                  position={task.position}
                  color={task.eventContainerStyle.backgroundColor}
                />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent
              sx={{
                py: "12px",
                px: 2,
                border: "1px solid black",
                backgroundColor: task.eventContainerStyle.backgroundColor,
                borderRadius: "5px",
              }}
            >
              <Typography variant="h6" component="span">
                {task.title.split("\n").map((item, key) => {
                  return (
                    <React.Fragment key={key}>
                      {item}
                      <br />
                    </React.Fragment>
                  );
                })}
              </Typography>
              <Typography>{task.description}</Typography>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      ))}

      {/* TODO: 1e80d46f-d2c2-48f0-b765-0738c7c17696 -task.booking is undefineddd. */}
      {selectedTask !== null &&
        selectedTask !== undefined &&
        selectedTask.booking && (
          <MyDialog
            open={open}
            setOpen={setOpen}
            booking_id={selectedTask.booking.id}
            tabValue={tabValue}
            setTabValue={setTabValue}
          />
        )}
    </>
  );
}

export default CustomizedTimeline;
