import React from "react";
import { Navigate } from "react-router-dom";
import { useDoin } from "../../ContextProviders/MainContextProvider";
import PropTypes from "prop-types";

const ProtectedRoute = ({ children }) => {
  const { user_token } = useDoin();
  if (!user_token || user_token === "No description set") {
    // Redirect to the login page if not authenticated
    console.log("Redirecting to login");
    return <Navigate to="/login" />;
  }

  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node,
};

export default ProtectedRoute;
