import axios from "axios";
class AuthManager {
  constructor(contextValues, navigate) {
    this.contextValues = contextValues;
    this.navigate = navigate;
    this.BACKEND_DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN;
  }

  login = async (values, setSnackbarMessage, setSnackbarOpen) => {
    try {
      const response = await fetch(
        `${this.BACKEND_DOMAIN}/api/v1/user/token2`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phone_number: values.phone_number,
            pin_number: values.pin_number,
          }),
        }
      );

      console.log("response", response);

      if (response.status === 404) {
        const data = await response.json();
        console.log("data", data.data);
        setSnackbarMessage(data.ui_message || "Resource not found");
        setSnackbarOpen(true);

        return;
      }

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("data", data);
      this.contextValues.update_user_token(data.data.token);
      this.contextValues.update_user_id(data.data.id);
      this.contextValues.setUserIsAuthenticated(true);
      this.contextValues.setBasicPermissions(data.data.permissions);

      this.navigate("/bookings");
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      setSnackbarMessage("An unexpected error occurred.");
      setSnackbarOpen(true);
    }
  };
  login_team = async (values, setSnackbarMessage, setSnackbarOpen) => {
    try {
      const response = await fetch(
        `${this.BACKEND_DOMAIN}/api/v1/staff/token2`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phone_number: values.phone_number,
            pin_number: values.pin_number,
          }),
        }
      );

      if (response.status === 404) {
        const data = await response.json();
        setSnackbarMessage(data.ui_message || "Resource not found");
        setSnackbarOpen(true);

        return;
      }

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      this.contextValues.update_user_token(data.data.token);
      this.contextValues.update_user_id(data.data.id);
      this.contextValues.setUserIsAuthenticated(true);
      this.contextValues.setBasicPermissions(data.data.permissions);

      this.navigate("/plan");
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      setSnackbarMessage("An unexpected error occurred.");
      setSnackbarOpen(true);
    }
  };

  register = async (values, setSnackbarMessage, setSnackbarOpen) => {
    try {
      const response = await axios.post(
        `${this.BACKEND_DOMAIN}/api/v1/user/register`,
        {
          email: values.email,
          password: values.password,
        }
      );

      console.log("response", response);
      const data = response.data;

      if (response.status === 400) {
        setSnackbarMessage(data.ui_message || "Bad request");
        setSnackbarOpen(true);
        return;
      }

      if (response.status === 409) {
        setSnackbarMessage(data.ui_message || "Conflict");
        setSnackbarOpen(true);
        return;
      }

      if (response.status !== 200 && response.status !== 201) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      if (response.status === 201) {
        setSnackbarMessage("Account created successfully");
        setSnackbarOpen(true);
        this.navigate("/login");
      }
      // Uncomment and adapt the following lines as needed for your context handling
      // this.contextValues.update_user_token(data.token);
      // this.contextValues.update_user_id(data.id);
      // this.contextValues.setUserIsAuthenticated(true);
    } catch (error) {
      console.error("There was a problem with the axios operation:", error);
      setSnackbarMessage(
        error.response?.data?.ui_message || "An unexpected error occurred."
      );
      setSnackbarOpen(true);
    }
  };
}

export default AuthManager;
