import React from "react";

import { Box, Container } from "@mui/material";
import CustomerList from "./CustomerList";

const CustomerPage = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="flex-start"
      //   height="calc(100vh - 100px - 100px
      maxHeight="xl"
      mt={10}
    >
      <Container
        maxWidth="sm"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CustomerList />
      </Container>
    </Box>
  );
};

export default CustomerPage;
