import React from "react";

import { Grid } from "@mui/material";
import BookingList from "./BookingList";
import { useDoin } from "../ContextProviders/MainContextProvider";
import RentalDetails from "./BookingInfo";
import CustomerTable from "./CustomerInfo";
import CarInformationTable from "./CarInfo";

const ProjectPage = () => {
  const { selectedProject } = useDoin();
  return (
    <Grid
      container
      spacing={1}
      style={{ height: "calc(100vh - 64px - 100px)" }}
      direction={"row"}
      justifyContent="flex-start"
    >
      {/* Column 1 */}
      <Grid item xs={3}>
        <BookingList />
      </Grid>

      {selectedProject && (
        <>
          <Grid item xs={3}>
            <RentalDetails pricingData={selectedProject} />
          </Grid>
          <Grid item xs={3}>
            <Grid item>
              <CustomerTable customer={selectedProject.customers} />
            </Grid>
            <Grid item>
              <CarInformationTable carData={selectedProject.car} />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ProjectPage;
