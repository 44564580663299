import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  TableContainer,
  TableHead,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDoin } from "../ContextProviders/MainContextProvider";
// import RemoveIcon from "@mui/icons-material/Remove";
// import AddIcon from "@mui/icons-material/Add";

const BACKEND_DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN;

const formatDateTime = (dateTimeString) => {
  /**
   * Formats a datetime string from 'YYYY-MM-DDTHH:MM' to 'DD-MM-YYYY @HH:MM'.
   *
   * @param {string} dateTimeString - The datetime string to be formatted.
   * @returns {string} - The formatted datetime string.
   *
   * @example
   * formatDateTime('2023-09-12T15:00') // Returns '12-09-2023 @15:00'
   * @example
   * formatDateTime('2022-12-31T23:59') // Returns '31-12-2022 @23:59'
   * @example
   * formatDateTime('2021-01-01T00:00') // Returns '01-01-2021 @00:00'
   */
  // Split the date and time
  const [date, time] = dateTimeString.split("T"); // ['2023-09-12', '15:00']

  // Reformat the date
  const dateArray = date.split("-"); // ['2023', '09', '12']
  const reversedDateArray = dateArray.reverse(); // ['12', '09', '2023']
  const formattedDate = reversedDateArray.join("-"); // '12-09-2023'

  // Remove the last 3 characters of time ('.00' if exists)
  const formattedTime = time.slice(0, -3); // '15:00'

  // Combine them
  const formattedDateTime = `${formattedDate} @${formattedTime}`; // '12-09-2023 @15:00'

  return formattedDateTime;
};

const PricingTable = ({ pricingData }) => {
  const [open, setOpen] = useState(false);
  const locations = ["AIRPORT", "AGIOS KIRIKOS", "UNDECIDED", "EVDILOS"];
  const insurances = ["BASIC", "CDW", "UNKNOWN"];
  const { projects, setProjects, setSelectedProject } = useDoin(); // eslint-disable-line no-unused-vars

  const { user_token } = useDoin();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const validationSchema = Yup.object().shape({
    pickup_location_name: Yup.string().required("Required"),
    dropoff_location_name: Yup.string().required("Required"),
    discount: Yup.number()
      .required("Required")
      .min(0, "Must be greater than or equal to 0"),
  });

  const formatValue = (value) => {
    if (value === null || value === undefined) return "None";
    if (typeof value === "boolean") return value ? "Yes" : "No";
    return value;
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const url = `${BACKEND_DOMAIN}/api/v1/booking/info/${pricingData.id}?token=${user_token}`;

    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pickup_location: values.pickup_location_name,
        dropoff_location: values.dropoff_location_name,
        discount: values.discount,
        // TODO: rename key insurance_type to insurance_name
        insurance_type: values.insurance_name,
        note: values.note,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success:", data);

        const taskIndex = projects.findIndex((task) => task.id === data.id);

        let updatedTasks;
        if (taskIndex !== -1) {
          // Task exists, so update it
          updatedTasks = projects.map((task, index) =>
            index === taskIndex ? { ...task, ...data } : task
          );
        } else {
          // Task doesn't exist, so add it
          updatedTasks = [...projects, data];
        }
        setSelectedProject(data);
        // Set the new tasks array
        setProjects(updatedTasks);

        console.log("Success:", data);
        handleClose();
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ margin: "20px", padding: "20px" }}
      >
        {" "}
        <Typography
          variant="h6"
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            // margin: "10px",
          }}
        >
          Booking Information
          <IconButton onClick={handleOpen}>
            <EditIcon />
          </IconButton>
        </Typography>
        <Table aria-label="price calculation details">
          <TableBody>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>Location</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="right">
                    {formatValue(pricingData.pickup_location_name)}
                  </TableCell>
                  <TableCell align="center">→</TableCell>
                  <TableCell align="right">
                    {formatValue(pricingData.dropoff_location_name)}
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="right">
                    {formatValue(formatDateTime(pricingData.start_date))}
                  </TableCell>
                  <TableCell align="center">→</TableCell>
                  <TableCell align="right">
                    {formatValue(formatDateTime(pricingData.end_date))}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>Insurance</TableCell>
                  <TableCell align="right">
                    {formatValue(pricingData.insurance_name)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow></TableRow>
              </TableBody>
            </Table>
            <TableRow>
              <TableCell component="th" scope="row">
                Number of Days
              </TableCell>
              <TableCell align="right">
                {formatValue(pricingData.computed_price.n_days)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Daily
              </TableCell>
              <TableCell align="right">
                {formatValue(pricingData.computed_price.daily_total)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Discount
              </TableCell>
              <TableCell align="right">
                {formatValue(pricingData.computed_price.discount)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Insurance Cost
              </TableCell>
              <TableCell align="right">
                {formatValue(pricingData.computed_price.insurance_cost)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Location Cost
              </TableCell>
              <TableCell align="right">
                {formatValue(pricingData.computed_price.location)}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Owed
              </TableCell>
              <TableCell align="right">
                {formatValue(pricingData.computed_price.owed)}
              </TableCell>
            </TableRow>
            {/* <TableRow>
            <TableCell component="th" scope="row">Rental</TableCell>
            <TableCell align="right">{formatValue(pricingData.computed_price.rental)}</TableCell>
          </TableRow> */}
            <TableRow>
              <TableCell component="th" scope="row">
                Total Booking Value
              </TableCell>
              <TableCell align="right">
                {formatValue(pricingData.computed_price.total)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Booking Notes
              </TableCell>
              <TableCell align="right">{pricingData.note || "None"}</TableCell>
            </TableRow>

            {/* ... Other rows ... */}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xl"
        fullWidth={true}
        PaperProps={{ style: { width: "20%", maxHeight: "80vh" } }}
      >
        <DialogTitle>Edit Booking</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              pickup_location_name: pricingData.pickup_location_name || "",
              dropoff_location_name: pricingData.dropoff_location_name || "",
              discount: pricingData.computed_price.discount || 0,
              note: pricingData.note || "",
              insurance_name: pricingData.insurance_name || "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, dirty }) => (
              <Form>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Pickup Location</InputLabel>
                  <Field
                    as={Select}
                    name="pickup_location_name"
                    label="Pickup Location"
                    onChange={handleChange}
                    value={values.pickup_location_name}
                  >
                    {locations.map((location) => (
                      <MenuItem key={location} value={location}>
                        {location}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Dropoff Location</InputLabel>
                  <Field
                    as={Select}
                    name="dropoff_location_name"
                    label="Dropoff Location"
                    onChange={handleChange}
                    value={values.dropoff_location_name}
                  >
                    {locations.map((location) => (
                      <MenuItem key={location} value={location}>
                        {location}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Insurance</InputLabel>
                  <Field
                    as={Select}
                    name="insurance_name"
                    label="Insurance"
                    onChange={handleChange}
                    value={values.insurance_name}
                  >
                    {insurances.map((insurance) => (
                      <MenuItem key={insurance} value={insurance}>
                        {insurance}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
                {/* <div style={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    onClick={() =>
                      setFieldValue(
                        "discount",
                        Math.max(-500, values.discount - 5)
                      )
                    }
                  >
                    <RemoveIcon />
                  </IconButton>
                  <TextField
                    name="discount"
                    type="number"
                    value={values.discount}
                    onChange={(e) =>
                      setFieldValue("discount", parseInt(e.target.value))
                    }
                    style={{ width: "120px", textAlign: "center" }}
                  />

                  <IconButton
                    onClick={() =>
                      setFieldValue(
                        "discount",
                        Math.min(100, values.discount + 5)
                      )
                    }
                  >
                    <AddIcon />
                  </IconButton>
                </div> */}
                <Field
                  as={TextField}
                  label="Note"
                  name="note"
                  onChange={handleChange}
                  value={values.note}
                  fullWidth
                  multiline
                  sx={{ mt: 2 }}
                />
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  sx={{ mt: 2 }}
                  disabled={!dirty}
                >
                  Save Changes
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

PricingTable.propTypes = {
  pricingData: PropTypes.shape({
    computed_price: PropTypes.shape({
      daily_total: PropTypes.number,
      discount: PropTypes.number,
      insurance_cost: PropTypes.number,
      location: PropTypes.number,
      n_days: PropTypes.number,
      owed: PropTypes.number,
      rental: PropTypes.number,
      total: PropTypes.number,
    }),
    dropoff_location_name: PropTypes.string,
    end_date: PropTypes.string,
    has_deposit: PropTypes.bool,
    insurance_name: PropTypes.string,
    is_passed_date: PropTypes.bool,
    money_from_payments: PropTypes.number,
    note: PropTypes.string,
    pickup_location_name: PropTypes.string,
    start_date: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
};

export default PricingTable;
