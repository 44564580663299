// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CSS Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body {
    height: 100%; /* This ensures the body takes the full viewport height */
    overflow: hidden; /* Optional: prevents scrolling on the whole page */
  }
  

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  

  .spin {
    animation: spin 1s ease-in-out;
  }
  `, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,cAAc;AACd;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;EACxB;;EAEA;IACE,YAAY,EAAE,yDAAyD;IACvE,gBAAgB,EAAE,mDAAmD;EACvE;;;EAGA;IACE;MACE,uBAAuB;IACzB;IACA;MACE,yBAAyB;IAC3B;EACF;;;EAGA;IACE,8BAA8B;EAChC","sourcesContent":["/* CSS Reset */\n* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n  }\n  \n  html, body {\n    height: 100%; /* This ensures the body takes the full viewport height */\n    overflow: hidden; /* Optional: prevents scrolling on the whole page */\n  }\n  \n\n  @keyframes spin {\n    from {\n      transform: rotate(0deg);\n    }\n    to {\n      transform: rotate(360deg);\n    }\n  }\n  \n\n  .spin {\n    animation: spin 1s ease-in-out;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
