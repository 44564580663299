/* eslint-disable */

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  IconButton,
  TableContainer,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Switch,
  FormControlLabel,
  MenuItem,
  TablePagination,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { Formik, Form, Field } from "formik";
import { useDoin } from "../ContextProviders/MainContextProvider";
import dayjs from "dayjs";

const BACKEND_DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN;

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const BasicDatePicker = ({ label, date, setDate }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={date}
        onChange={(newValue) => {
          setDate(newValue);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};

const PaymentList = () => {
  const { user_token } = useDoin();
  const [payments, setPayments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(16);
  const [totalPayments, setTotalPayments] = useState(0);

  const [startDate, setStartDate] = useState(dayjs().subtract(300, "day"));
  const [endDate, setEndDate] = useState(dayjs().add(300, "day"));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 20));
    setPage(0);
  };

  const fetchPayments = async (start, end) => {
    const startFormat = start.format("YYYY-MM-DD");
    const endFormat = end.format("YYYY-MM-DD");
    try {
      const response = await axios.get(
        `${BACKEND_DOMAIN}/api/v1/dashboard/payment/start/${startFormat}/end/${endFormat}?token=${user_token}`
      );
      setPayments(response.data);
    } catch (error) {
      console.error("Error fetching payments:", error);
    }
  };

  useEffect(() => {
    fetchPayments(startDate, endDate);
  }, [startDate, endDate]);

  const handleRowClick = (payment) => {
    setSelectedPayment(payment);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPayment(null);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredPayments = payments.filter(
    (payment) =>
      payment.note.toLowerCase().includes(searchTerm.toLowerCase()) ||
      payment.payment_type.toLowerCase().includes(searchTerm.toLowerCase()) ||
      payment.amount.toString().includes(searchTerm.toLowerCase()) ||
      payment.customers.first_name
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      payment.created_by.toLowerCase().includes(searchTerm.toLowerCase()) ||
      payment.is_deposit.toString().includes(searchTerm.toLowerCase()) ||
      new Date(payment.timestamp)
        .toLocaleString("el-GR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        })
        .includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    // Check if filteredPayments is null or empty
    if (!filteredPayments || filteredPayments.length === 0) {
      setTotalPayments(0);
      return;
    }

    // Assuming each payment object in the array has an 'amount' property
    const totalAmount = filteredPayments.reduce(
      (sum, payment) => sum + Number(payment.amount),
      0
    );
    setTotalPayments(totalAmount);
  }, [filteredPayments]);

  return (
    <>
      <Typography variant="h5" style={{ paddingBottom: "20px" }}>
        Payments - Total: {totalPayments}
      </Typography>
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <TextField
          variant="outlined"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <IconButton onClick={() => setSearchTerm("")} edge="end">
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          style={{ marginBottom: "20px" }}
        />
        <Box display="flex" justifyContent="flex-end" flexWrap="wrap">
          <BasicDatePicker
            label="Start Date"
            date={startDate}
            setDate={setStartDate}
          />
          <BasicDatePicker
            label="End Date"
            date={endDate}
            setDate={setEndDate}
          />
        </Box>
      </Box>
      <Divider style={{ marginBottom: "20px" }} />

      <TableContainer style={{ maxHeight: "calc(100vh - 64px - 300px)" }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>Amount</TableCell>
              <TableCell>Payment Type</TableCell>
              <TableCell>Note</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Is Deposit</TableCell>
              <TableCell>Timestamp</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredPayments.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredPayments
            ).map((payment) => (
              <TableRow
                key={payment.id}
                hover
                onClick={() => handleRowClick(payment)} // Ensure handleRowClick is defined
              >
                <TableCell
                  sx={{
                    maxWidth: 100,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {payment.amount}
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: 100,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {payment.payment_type}
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: 100,
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                  }}
                >
                  {payment.note}
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: 100,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {payment.created_by}
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: 100,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {payment.customers.first_name}
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: 100,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {payment.is_deposit ? "Yes" : "No"}
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: 100,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {new Date(payment.timestamp).toLocaleString("el-GR", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredPayments.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Payment</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              amount: selectedPayment?.amount ?? 0,
              payment_type: selectedPayment?.payment_type ?? "CASH",
              note: selectedPayment?.note ?? "",
              is_deposit: selectedPayment?.is_deposit ?? false,
            }}
            onSubmit={(values, { setSubmitting }) => {
              axios
                .put(
                  `${BACKEND_DOMAIN}/api/v1/payment/${selectedPayment.id}?token=${user_token}`,
                  {
                    amount: values.amount,
                    payment_type: values.payment_type,
                    note: values.note,
                    is_deposit: values.is_deposit,
                  }
                )
                //eslint-disable-next-line
                .then((response) => {
                  setSubmitting(false);
                  handleClose();
                  fetchPayments(); // Refresh payment list after update
                })
                .catch((error) => {
                  console.error("Error:", error);
                  setSubmitting(false);
                });
            }}
          >
            {({ values, handleChange, handleSubmit, isSubmitting, dirty }) => (
              <Form onSubmit={handleSubmit}>
                <Field
                  as={TextField}
                  type="number"
                  label="Amount"
                  name="amount"
                  onChange={handleChange}
                  value={values.amount}
                  fullWidth
                  sx={{ mt: 2 }}
                />
                <Field
                  as={TextField}
                  select
                  label="Payment Type"
                  name="payment_type"
                  onChange={handleChange}
                  value={values.payment_type}
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  {[
                    "CASH",
                    "CARD",
                    "OTHER",
                    "PIREOS",
                    "ALPHA BANK",
                    "VIVA",
                    "EUROBANK",
                    "ETHNIKI",
                  ].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Field>
                <Field
                  as={TextField}
                  label="Note"
                  name="note"
                  onChange={handleChange}
                  value={values.note}
                  fullWidth
                  multiline
                  sx={{ mt: 2 }}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.is_deposit}
                      onChange={handleChange}
                      name="is_deposit"
                    />
                  }
                  label="Is Deposit"
                />
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={!dirty || isSubmitting}
                  sx={{ mt: 2 }}
                >
                  Save Changes
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PaymentList;
