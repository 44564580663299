import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import PropTypes from "prop-types";

const ProjectAddDialog = ({ open, handleClose, handleSubmit, title }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <Formik
        initialValues={{
          name: "",
          description: "",
          show_in_flow: false, // Added boolean field
          show_in_apps: false, // Added boolean field
          show_in_payements: false, // Added boolean field
        }}
        onSubmit={handleSubmit}
      >
        {({ submitForm, setFieldValue }) => (
          <Form>
            <DialogContent>
              <Field
                as={TextField}
                name="name"
                label="Project Name"
                fullWidth
                style={{ marginBottom: "16px" }}
              />
              <Field
                as={TextField}
                name="description"
                label="Project Description"
                fullWidth
                multiline
                rows={4}
                style={{ marginBottom: "16px" }}
              />
              <FormControlLabel
                control={
                  <Field
                    as={Switch}
                    type="checkbox"
                    name="show_in_flow"
                    onChange={(event) =>
                      setFieldValue("show_in_flow", event.target.checked)
                    }
                  />
                }
                label="Show in Flow"
              />
              <FormControlLabel
                control={
                  <Field
                    as={Switch}
                    type="checkbox"
                    name="show_in_apps"
                    onChange={(event) =>
                      setFieldValue("show_in_apps", event.target.checked)
                    }
                  />
                }
                label="Show in Apps"
              />
              <FormControlLabel
                control={
                  <Field
                    as={Switch}
                    type="checkbox"
                    name="show_in_payments"
                    onChange={(event) =>
                      setFieldValue("show_in_payments", event.target.checked)
                    }
                  />
                }
                label="Show in Payments"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button color="primary" onClick={submitForm}>
                CREATE PROJECT
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

ProjectAddDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ProjectAddDialog;
