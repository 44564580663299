const formatDateTime = (dateTimeString) => {
  /**
   * Formats a datetime string from 'YYYY-MM-DDTHH:MM' to 'DD-MM-YYYY @HH:MM'.
   *
   * @param {string} dateTimeString - The datetime string to be formatted.
   * @returns {string} - The formatted datetime string.
   *
   * @example
   * formatDateTime('2023-09-12T15:00') // Returns '12-09-2023 @15:00'
   * @example
   * formatDateTime('2022-12-31T23:59') // Returns '31-12-2022 @23:59'
   * @example
   * formatDateTime('2021-01-01T00:00') // Returns '01-01-2021 @00:00'
   */
  // Split the date and time
  const [date, time] = dateTimeString.split("T"); // ['2023-09-12', '15:00']

  // Reformat the date
  const dateArray = date.split("-"); // ['2023', '09', '12']
  const reversedDateArray = dateArray.reverse(); // ['12', '09', '2023']
  const formattedDate = reversedDateArray.join("-"); // '12-09-2023'

  // Remove the last 3 characters of time ('.00' if exists)
  const formattedTime = time.slice(0, -3); // '15:00'

  // Combine them
  const formattedDateTime = `${formattedDate} @${formattedTime}`; // '12-09-2023 @15:00'

  return formattedDateTime;
};

export { formatDateTime }; // Export the function
