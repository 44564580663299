import React, { useEffect, useState } from "react";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Box,
  Container,
} from "@mui/material";
import { useDoin } from "../ContextProviders/MainContextProvider";
import { Formik, Form, Field } from "formik";

const BACKEND_DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN;

const InsuranceList = () => {
  const { user_token, setSnackbarOpen, setSnackbarMessage } = useDoin();
  const [insurances, setInsurances] = useState([]);
  const [selectedInsurance, setSelectedInsurance] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  const handleRowClick = (insurance) => {
    setSelectedInsurance(insurance);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenDeleteConfirm(false);
    setSelectedInsurance(null);
  };

  const fetchInsurances = async () => {
    try {
      const response = await axios.get(
        `${BACKEND_DOMAIN}/api/v1/insurance?token=${user_token}`
      );
      setInsurances(response.data);
    } catch (error) {
      console.error("Error fetching insurances:", error);
    }
  };

  useEffect(() => {
    fetchInsurances();
  }, []);

  const updateItems = (
    data,
    insurances,
    setSelectedInsurance,
    setInsurances
  ) => {
    const insuranceIndex = insurances.findIndex((item) => item.id === data.id);
    let updatedInsurances;
    if (insuranceIndex !== -1) {
      updatedInsurances = insurances.map((item, index) =>
        index === insuranceIndex ? { ...item, ...data } : item
      );
    } else {
      updatedInsurances = [...insurances, data];
    }
    setSelectedInsurance(data);
    setInsurances(updatedInsurances);
  };

  const handleDelete = async () => {
    try {
      //eslint-disable-next-line
      const response = await axios.delete(
        `${BACKEND_DOMAIN}/api/v1/insurance/${selectedInsurance.id}?token=${user_token}`
      );

      setInsurances(
        insurances.filter((insurance) => insurance.id !== selectedInsurance.id)
      );
      setSnackbarOpen(true);
      setSnackbarMessage("Insurance deleted");
      handleClose();
    } catch (error) {
      console.error("Error deleting insurance:", error);

      setSnackbarOpen(true);
      setSnackbarMessage(
        error.response.data.ui_message || "Error deleting insurance"
      );
      handleClose();
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="flex-start"
      maxHeight="xl"
      mt={10}
    >
      <Container
        maxWidth="sm"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          style={{ paddingBottom: "20px", textAlign: "center" }}
        >
          Insurances
        </Typography>

        <Divider style={{ marginBottom: "20px" }} />

        <TableContainer
          style={{
            maxHeight: "300px",
            width: "400px",
          }}
        >
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Cost</TableCell>
                <TableCell>Info(EN)</TableCell>
                <TableCell>Info(GR)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {insurances.map((insurance) => (
                <TableRow
                  key={insurance.id}
                  hover
                  onClick={() => handleRowClick(insurance)}
                >
                  <TableCell>{insurance.name}</TableCell>
                  <TableCell>{insurance.cost}</TableCell>
                  <TableCell>{insurance.info_en}</TableCell>
                  <TableCell>{insurance.info_gr}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Edit Insurance</DialogTitle>
          <DialogContent>
            <Formik
              initialValues={{
                name: selectedInsurance?.name ?? "",
                cost: selectedInsurance?.cost ?? 0.0,
                info_en: selectedInsurance?.info_en ?? "",
                info_gr: selectedInsurance?.info_gr ?? "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                axios
                  .put(
                    `${BACKEND_DOMAIN}/api/v1/insurance/${selectedInsurance.id}?token=${user_token}`,
                    {
                      name: values.name,
                      cost: values.cost,
                      info_en: values.info_en,
                      info_gr: values.info_gr,
                    }
                  )
                  .then((response) => {
                    setSubmitting(false);
                    updateItems(
                      response.data,
                      insurances,
                      setSelectedInsurance,
                      setInsurances
                    );
                    setSnackbarOpen(true);
                    setSnackbarMessage("Insurance updated");
                    handleClose();
                  })
                  .catch((error) => {
                    console.error("Error:", error);
                    setSnackbarOpen(true);
                    setSnackbarMessage("Error updating insurance");

                    setSubmitting(false);
                  });
              }}
            >
              {({ values, handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Field
                    as={TextField}
                    label="Name"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <Field
                    as={TextField}
                    label="Cost"
                    name="cost"
                    type="number"
                    onChange={handleChange}
                    value={values.cost}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <Field
                    as={TextField}
                    label="Info(EN)"
                    name="info_en"
                    onChange={handleChange}
                    value={values.info_en}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <Field
                    as={TextField}
                    label="Info(GR)"
                    name="info_gr"
                    onChange={handleChange}
                    value={values.info_gr}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    sx={{ mt: 2 }}
                  >
                    Save Changes
                  </Button>
                  <IconButton
                    color="error"
                    sx={{ mt: 2, ml: 2 }}
                    onClick={() => setOpenDeleteConfirm(true)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openDeleteConfirm}
          onClose={() => setOpenDeleteConfirm(false)}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this insurance?
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteConfirm(false)}>Cancel</Button>
            <Button onClick={handleDelete} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

InsuranceList.propTypes = {};

export default InsuranceList;
