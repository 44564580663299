import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  List,
  Paper,
  Box,
  Typography,
  Divider,
  IconButton,
  Snackbar,
} from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
import { useDoin } from "../ContextProviders/MainContextProvider";
import ProjectManager from "../managers/ProjectManager";
import ProjectAddDialog from "./ProjectAddDialog";
import ProjectEditModal from "./ProjectEditDialog";
import PropTypes from "prop-types";
import CustomListItem from "./bookingcomp";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";

const BACKEND_DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN;

// const highlightedStyle = {
//   backgroundColor: "#f0f0f0", // Example highlight color, change as needed
// };

const BookingList = ({ show = null, onClickProjectItem = () => {} }) => {
  const { setSelectedProject } = useDoin();
  const { user_token } = useDoin();
  const { setProjects } = useDoin();
  // eslint-disable-next-line no-unused-vars
  const [setSelectedItemId] = useState(null);
  const [toastMessage, setToastMessage] = useState("");
  const [toastOpen, setToastOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const {
    // Search
    editProjectModalOpen,
    addProjectModalOpen,
    setAddProjectModalOpen,
    setEditProjectModalOpen,

    // Project
    projects,
    selectedProject,
    setSelectedProjectId,
  } = useDoin();

  const contextValues = useDoin(); // Getting context values
  const projectManager = new ProjectManager(contextValues);

  const fetchProjects = async () => {
    try {
      const response = await axios.get(
        `${BACKEND_DOMAIN}/api/v1/booking?token=${user_token}`
      );
      setProjects(response.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line no-unused-vars
  const handleItemClick = (project) => {
    setSelectedProject(project);
    setSelectedItemId(project.id);
  };

  // eslint-disable-next-line no-unused-vars
  const handleProjectAddClick = (group) => {
    setAddProjectModalOpen(true);
  };
  const handleProjectEditClick = (project) => {
    setEditProjectModalOpen(true);
    setSelectedProjectId(project.id);
    setSelectedProject(project);
  };

  const handleListProjectItemClick = async (project) => {
    console.log("clicked", project);
    setSelectedProjectId(project.id);
    setSelectedProject(project);
    onClickProjectItem(project);
  };
  const paperStyle = {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    height: "calc(100vh - 160px)",
    marginLeft: "20px",
    marginBottom: "20px",
    marginTop: "20px",
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredProjects = projects.filter((booking) =>
    booking.search_term.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Paper style={paperStyle}>
      <Typography variant="h5" style={{ paddingBottom: "20px" }}>
        Bookings
      </Typography>
      {/* 
      <Typography variant="body1" style={{ paddingBottom: "20px" }}>
        Select a Booking to view details.
      </Typography> */}

      <Box display="flex" alignItems="center" sx={{ paddingLeft: "10px" }}>
        {/* <Typography variant="h6" component="p">
          Bookings
        </Typography> */}
        {/* <IconButton onClick={handleProjectAddClick}>
          <AddIcon />
        </IconButton> */}
      </Box>

      {/* Search Bar */}
      <TextField
        variant="outlined"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: searchTerm && (
            <InputAdornment position="end">
              <IconButton onClick={handleClearSearch} edge="end">
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        style={{ marginBottom: "20px" }}
      />
      <Divider style={{ borderWidth: 2 }} />

      <Box sx={{ overflow: "auto" }}>
        <List>
          {filteredProjects.map(
            (booking) =>
              (show === null || (show !== null && booking[show])) && (
                <CustomListItem
                  key={booking.id}
                  booking={booking}
                  handleProjectEditClick={handleProjectEditClick}
                  handleBookingClick={handleListProjectItemClick}
                />
                // <ListItem
                //   key={booking.id}
                //   onClick={() => handleListProjectItemClick(booking)}
                //   style={
                //     selectedProject && selectedProject.id === booking.id
                //       ? highlightedStyle
                //       : null
                //   }
                // >
                //   <ListItemText
                //     primary={booking.start_date + " - " + booking.end_date}
                //     secondary={
                //       booking.pickup_location_name +
                //       " - " +
                //       booking.dropoff_location_name
                //     }
                //   />
                //   <IconButton onClick={() => handleProjectEditClick(booking)}>
                //     <EditIcon />
                //   </IconButton>
                // </ListItem>
              )
          )}
        </List>
      </Box>

      <ProjectAddDialog
        open={addProjectModalOpen}
        handleClose={() => setAddProjectModalOpen(false)}
        handleSubmit={projectManager.handleProjectSubmit}
        title="Add a New Project"
      />

      {selectedProject && (
        <ProjectEditModal
          open={editProjectModalOpen}
          handleClose={() => setEditProjectModalOpen(false)}
          handleSubmit={projectManager.handleProjectEditSubmit}
          handleDelete={() =>
            projectManager.handleDeleteProject({
              setToastMessage,
              setToastOpen,
            })
          }
          initialTask={selectedProject}
          title="Edit Project"
        />
      )}

      <Snackbar
        open={toastOpen}
        autoHideDuration={6000}
        onClose={() => setToastOpen(false)}
        message={toastMessage}
      />
    </Paper>
  );
};

BookingList.propTypes = {
  show: PropTypes.bool,
  onClickProjectItem: PropTypes.func,
};

export default BookingList;
