class ProjectManager {
  constructor(contextValues) {
    this.contextValues = contextValues;
    this.BACKEND_DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN;
  }
  retrive_projects = () => {
    fetch(
      `${this.BACKEND_DOMAIN}/api/v1/project?token=${this.contextValues.user_token}`
    )
      .then((response) => response.json())
      .then((data) => this.contextValues.setProjects(data))
      .catch((error) => console.error("Error fetching groups:", error));
  };
  handleProjectSubmit = (values, { resetForm }) => {
    fetch(
      `${this.BACKEND_DOMAIN}/api/v1/project?token=${this.contextValues.user_token}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      }
    )
      .then((response) => response.json())
      //eslint-disable-next-line
      .then((newTask) => {
        // setTasks([...tasks, newTask]);
        resetForm();
        this.contextValues.setAddProjectModalOpen(false);
        this.retrive_projects();
        // triggerUpdate2();
      })
      .catch((error) => console.error("Error adding task:", error));
  };
  handleProjectEditSubmit = (values, { resetForm }) => {
    console.log(values);
    fetch(
      `${this.BACKEND_DOMAIN}/api/v1/project/${this.contextValues.selectedProjectId}?token=${this.contextValues.user_token}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      }
    )
      .then((response) => response.json())
      .then((updatedTask) => {
        this.contextValues.setTasks(
          this.contextValues.tasks.map((task) =>
            task.id === updatedTask.id ? updatedTask : task
          )
        );
        resetForm();
        this.contextValues.setEditProjectModalOpen(false);
        this.retrive_projects();
        // triggerUpdate2();
        // setEditingTask(null);
      })
      .catch((error) => console.error("Error updating task:", error));
  };
  handleDeleteProject = ({ setToastMessage, setToastOpen }) => {
    fetch(
      `${this.BACKEND_DOMAIN}/api/v1/project/${this.contextValues.selectedProject.id}?token=${this.contextValues.user_token}`,
      {
        method: "DELETE",
      }
    )
      .then((response) => {
        if (response.ok) {
          this.contextValues.setEditProjectModalOpen(false);
          this.retrive_projects();
          response.json().then((data) => {
            console.log(data);
            setToastMessage(data.ui_message || "Project deleted successfully");
          });
          //   triggerUpdate2();
        } else {
          response.json().then((data) => {
            setToastMessage(data.ui_message || "Failed to delete the group");
          });
        }
        setToastOpen(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        setToastMessage("An error occurred");
        setToastOpen(true);
      });
  };
}

export default ProjectManager;
