import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
  IconButton,
} from "@mui/material";
import { useDoin } from "../ContextProviders/MainContextProvider";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
const BACKEND_DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN;
const paymentTypes = ["CASH", "CARD", "OTHER"];

const PaymentsList = ({
  booking_id,
  customer_id,
  onPaymentCrudSuccess = () => {},
  tabValue,
}) => {
  console.log("PAYMENTLIST: ", booking_id, customer_id);
  const [payments, setPayments] = useState([]);
  const { user_token, setSnackbarOpen, setSnackbarMessage } = useDoin();
  const [Loading, setLoading] = useState(true);

  const [openDialog, setOpenDialog] = useState(false);
  const [paymentFormData, setPaymentFormData] = useState({
    amount: "",
    payment_type: "",
    note: "",
  });
  const fetchPayments = async () => {
    console.log("PAYMENTLIST: FETCH PAYMENTS: ");
    setLoading(true);
    try {
      const response = await axios.get(
        `${BACKEND_DOMAIN}/api/v1/payment/bybooking/${booking_id}?token=${user_token}`
      );
      setPayments(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching payments:", error);
      setSnackbarMessage(error.response.data.ui_message);
      setSnackbarOpen(true);
      setLoading(false);
    }
  };
  useEffect(() => {
    console.log("PAYMENTLIST: USEEFFECT: ");
    fetchPayments();
  }, [tabValue]); // eslint-disable-line react-hooks/exhaustive-deps

  if (Loading) {
    return <div>Loading...</div>;
  }

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleFormChange = (e) => {
    setPaymentFormData({ ...paymentFormData, [e.target.name]: e.target.value });
  };

  const handleAddPayment = async () => {
    console.log("PAYMENTLIST: handleAddPayment: ");
    setLoading(true);
    try {
      const response = await axios.post(
        `${BACKEND_DOMAIN}/api/v1/payment?token=${user_token}`,
        {
          ...paymentFormData,
          booking_id,
          customer_id: customer_id, // Replace with actual customer ID
          is_deposit: true, // Adjust according to your needs
          token: user_token,
        }
      );

      if (response.status === 200) {
        fetchPayments();
        onPaymentCrudSuccess();
        setSnackbarOpen(true);
        setSnackbarMessage("Payment added successfully");
        handleCloseDialog();
      } else {
        console.error("Error adding payment: ", response.status);
        setSnackbarOpen(true);
        setSnackbarMessage("Error adding payment");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error adding payment:", error);
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.ui_message);
      setLoading(false);
    }
  };
  return (
    <>
      <Button variant="contained" onClick={handleOpenDialog}>
        Add Payment
      </Button>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Add a New Payment</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="amount"
            label="Amount"
            type="number"
            fullWidth
            value={paymentFormData.amount}
            onChange={handleFormChange}
          />
          <TextField
            select
            margin="dense"
            name="payment_type"
            label="Payment Type"
            fullWidth
            value={paymentFormData.payment_type}
            onChange={handleFormChange}
          >
            {paymentTypes.map((paymentType) => (
              <MenuItem key={paymentType} value={paymentType}>
                {paymentType}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            name="note"
            label="Note"
            multiline
            rows={4}
            fullWidth
            value={paymentFormData.note}
            onChange={handleFormChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleAddPayment}>Add Payment</Button>
        </DialogActions>
      </Dialog>
      <List>
        {payments.map((payment) => (
          <ListItem key={payment.id}>
            <ListItemText
              primary={payment.customers.first_name}
              secondary={`€${payment.amount} - ${payment.payment_type} - ${payment.note}`}
            />
            <IconButton
              aria-label="delete"
              onClick={async () => {
                try {
                  //eslint-disable-next-line
                  const response = await axios.delete(
                    `${BACKEND_DOMAIN}/api/v1/payment/${payment.id}?token=${user_token}`
                  );
                  fetchPayments();
                  onPaymentCrudSuccess();

                  setSnackbarOpen(true);
                  setSnackbarMessage("Payment deleted successfully");
                } catch (error) {
                  console.error("Error deleting payment:", error);
                  setSnackbarOpen(true);
                  setSnackbarMessage("Error deleting payment");
                  setSnackbarMessage(error.response.data.ui_message);
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </>
  );
};

PaymentsList.propTypes = {
  booking_id: PropTypes.number.isRequired,
  customer_id: PropTypes.number.isRequired,
  onPaymentCrudSuccess: PropTypes.func,
  tabValue: PropTypes.number,
};

export default PaymentsList;
