import React, { useContext, useReducer } from "react";
import PropTypes from "prop-types";

const DoinContext = React.createContext();

const DoinReducer = (state, action) => {
  switch (action.type) {
    case "update_user_token":
      return { ...state, user_token: action.payload };
    case "update_user_id":
      return { ...state, user_id: action.payload };
    case "update_user_role":
      return { ...state, user_role: action.payload };
    case "setTriggerRerender":
      return { ...state, triggerRerender: action.payload };
    case "setSearchTerm":
      return { ...state, searchTerm: action.payload };
    case "setAddProjectModalOpen":
      return { ...state, addProjectModalOpen: action.payload };
    case "setEditProjectModalOpen":
      return { ...state, editProjectModalOpen: action.payload };
    case "setProjects":
      return { ...state, projects: action.payload };
    case "setSelectedProjectId":
      return { ...state, selectedProjectId: action.payload };
    case "setSelectedProject":
      return { ...state, selectedProject: action.payload };

    case "setUserIsAuthenticated":
      return { ...state, user_is_authenticated: action.payload };

    // NOTIFICATIONS
    case "setSnackbarOpen":
      return { ...state, snackbarOpen: action.payload };
    case "setSnackbarMessage":
      return { ...state, snackbarMessage: action.payload };

    case "setBasicPermissions":
      return { ...state, basicPermissions: action.payload };

    default:
      return state;
  }
};

// const [teamMembers, setTeamMembers] = useState([]);
// const [selectedMember, setSelectedMember] = useState(null);

// const [applications, setApplications] = useState([]);
// const [selectedApplication, setSelectedApplication] = useState(null);

export const useDoin = () => {
  return useContext(DoinContext);
};

export const DoinProvider = ({ children }) => {
  const initialState = {
    user_token: null,
    user_id: null,
    user_role: null,
    user_is_authenticated: false,

    editModalOpen: false,
    editingTask: { label: null },
    addModalOpen: false,
    groups: [],
    groupNodeID: null,
    showDrawer: true,
    searchTerm: "",
    addProjectModalOpen: false,
    editProjectModalOpen: false,
    projects: [],
    selectedProjectId: 1,
    selectedGroupId: null,

    selectedProject: null,

    // NOTIFICATIONS
    snackbarOpen: false,
    snackbarMessage: "",

    basicPermissions: {
      can_view_bookings: false,
      can_view_payments: false,
      can_view_customers: false,
      can_view_insurances: false,
      can_view_cars: false,
      can_view_locations: false,
      can_view_plan: false,
      can_view_timeline: false,
      can_view_assets: false,
    },
  };

  const [state, dispatch] = useReducer(DoinReducer, initialState);

  const update_user_token = (new_user_token) => {
    console.log("Updating user token to: ", new_user_token);
    dispatch({ type: "update_user_token", payload: new_user_token });
  };

  const update_user_id = (new_user_id) => {
    dispatch({ type: "update_user_id", payload: new_user_id });
  };

  const update_user_role = (new_user_role) => {
    dispatch({ type: "update_user_role", payload: new_user_role });
  };

  const setBasicPermissions = (new_basic_permissions) => {
    dispatch({ type: "setBasicPermissions", payload: new_basic_permissions });
  };

  const setTriggerRerender = (new_triggerRerender) => {
    dispatch({ type: "setTriggerRerender", payload: new_triggerRerender });
  };

  const setEditModalOpen = (new_editModalOpen) => {
    dispatch({ type: "setEditModalOpen", payload: new_editModalOpen });
  };

  const setSearchTerm = (new_searchTerm) => {
    dispatch({ type: "setSearchTerm", payload: new_searchTerm });
  };

  const setAddProjectModalOpen = (new_addProjectModalOpen) => {
    dispatch({
      type: "setAddProjectModalOpen",
      payload: new_addProjectModalOpen,
    });
  };

  const setProjects = (new_projects) => {
    dispatch({ type: "setProjects", payload: new_projects });
  };

  const setEditProjectModalOpen = (new_editProjectModalOpen) => {
    dispatch({
      type: "setEditProjectModalOpen",
      payload: new_editProjectModalOpen,
    });
  };

  const setSelectedProjectId = (new_selectedProjectId) => {
    dispatch({
      type: "setSelectedProjectId",
      payload: new_selectedProjectId,
    });
  };

  const setSelectedProject = (new_selectedProject) => {
    dispatch({
      type: "setSelectedProject",
      payload: new_selectedProject,
    });
  };

  const setUserIsAuthenticated = (new_user_is_authenticated) => {
    dispatch({
      type: "setUserIsAuthenticated",
      payload: new_user_is_authenticated,
    });
  };

  const setSnackbarOpen = (new_snackbarOpen) => {
    dispatch({
      type: "setSnackbarOpen",
      payload: new_snackbarOpen,
    });
  };

  const setSnackbarMessage = (new_snackbarMessage) => {
    dispatch({
      type: "setSnackbarMessage",
      payload: new_snackbarMessage,
    });
  };

  return (
    <DoinContext.Provider
      value={{
        ...state,
        // Auth
        update_user_token,
        update_user_id,
        update_user_role,
        setUserIsAuthenticated,
        setBasicPermissions,

        setTriggerRerender,
        setEditModalOpen,

        setSearchTerm,

        setProjects,

        // Project
        setAddProjectModalOpen,
        setEditProjectModalOpen,

        setSelectedProjectId,
        setSelectedProject,

        // Notebook

        // Notifications
        setSnackbarOpen,
        setSnackbarMessage,
      }}
    >
      {children}
    </DoinContext.Provider>
  );
};

DoinProvider.propTypes = {
  children: PropTypes.node,
};

export default DoinContext;
