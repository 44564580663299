import React from "react";
// import { useNavigate, useLocation } from "react-router-dom";
import {
  //   AppBar,
  //   Box,
  //   Toolbar,
  //   Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// import LogoutIcon from "@mui/icons-material/Logout";
// import PropTypes from "prop-types";
// import { useDoin } from "./ContextProviders/MainContextProvider";
import HeaderMobile from "./HeaderMobile";
import HeaderDesktop from "./HeaderDesktop";

// const LEFT_BUTTON_GROUP_MARGIN_RIGHT = "10px";
const Header = () => {
  //   const navigate = useNavigate();
  //   const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (isMobile) {
    return <HeaderMobile />;
  }

  return <HeaderDesktop />;
};

export default Header;
