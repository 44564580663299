import React from "react";
import { Box, Link } from "@mui/material";
import { Typography } from "@mui/material";
import { useTheme } from "@emotion/react";

function Footer() {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        p: 3,
        // bgcolor: "background.paper",
        boxShadow: "0px -5px 5px -5px rgba(0,0,0,0.2)",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        maxHeight: "10px",
        display: "flex", // Enable Flexbox
        alignItems: "center", // Center vertically
        justifyContent: "center", // Center horizontally
        ...theme.footer,
      }}
    >
      <Typography variant="body2" color="white" align="center">
        {"© "}
        <Link
          href="https://pamfilis.dev"
          target="_blank"
          rel="noopener noreferrer"
          color="inherit"
        >
          pamfilis.dev
        </Link>
      </Typography>
    </Box>
  );
}

export default Footer;
