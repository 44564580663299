import React from "react";
import { useNavigate } from "react-router-dom";

import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDoin } from "./ContextProviders/MainContextProvider";
import { useLocation } from "react-router-dom";

const HeaderMobile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    update_user_token,
    setUserIsAuthenticated,
    user_is_authenticated,
    basicPermissions,
  } = useDoin();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    update_user_token(null);
    setUserIsAuthenticated(false);
    navigate("/login");
  };

  const handleNavigate = (path) => {
    navigate(path);
    handleMenuClose();
  };

  return (
    <AppBar position="static">
      <Toolbar sx={(theme) => ({ ...theme.header })}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuOpen}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          {user_is_authenticated && basicPermissions.can_view_bookings && (
            <MenuItem
              onClick={() => handleNavigate("/bookings")}
              selected={location.pathname === "/bookings"}
            >
              Bookings
            </MenuItem>
          )}
          {user_is_authenticated && basicPermissions.can_view_customers && (
            <MenuItem
              onClick={() => handleNavigate("/customers")}
              selected={location.pathname === "/customers"}
            >
              Customers
            </MenuItem>
          )}
          {user_is_authenticated && basicPermissions.can_view_payments && (
            <MenuItem
              onClick={() => handleNavigate("/payments")}
              selected={location.pathname === "/payments"}
            >
              Payments
            </MenuItem>
          )}
          {user_is_authenticated && basicPermissions.can_view_insurances && (
            <MenuItem
              onClick={() => handleNavigate("/insurance")}
              selected={location.pathname === "/insurance"}
            >
              Insurance
            </MenuItem>
          )}
          {user_is_authenticated && basicPermissions.can_view_locations && (
            <MenuItem
              onClick={() => handleNavigate("/location")}
              selected={location.pathname === "/location"}
            >
              Location
            </MenuItem>
          )}
          {user_is_authenticated && (
            <MenuItem
              onClick={() => handleNavigate("/plan")}
              selected={location.pathname === "/plan"}
            >
              Plan
            </MenuItem>
          )}
          {/* Additional menu items can be added here */}
        </Menu>
        <Typography>
          {location.pathname.replace("/", "").toUpperCase()}
        </Typography>{" "}
        <Box flexGrow={1} />
        {user_is_authenticated && (
          <Button
            color="inherit"
            onClick={handleLogout}
            startIcon={<LogoutIcon />}
          >
            Logout
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default HeaderMobile;
